<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left-while.png" />
      <span>每日签到</span>
    </div>
    <div class="mian-content">
      <div class="bg">
        <div class="gold-box">
          <img class="gold-coin2" src="@/assets/image/gold-coin2.png" />
          <img class="gold-coin2-bg" src="@/assets/image/gold-coin2-bg.png" />
        </div>
      </div>

      <div class="content">
        <div class="sign-in">
          <div class="sign-top">
            <img class="img-left" src="@/assets/image/sign-arrow.png" />
            <span>{{ isComplete ? "今日签到成功" : "未签到" }}</span>
            <img class="img-right" src="@/assets/image/sign-arrow.png" />
          </div>
          <div class="register-calendar-main">
            <div
              class="register-calendar-week"
              v-for="item in weekList"
              :key="item"
            >
              <div>{{ item }}</div>
            </div>
            <div
              class="register-calendar-day"
              v-for="(item, index) in answerRegisterStatus.dateList"
              :key="index"
            >
              <div
                :class="
                  item.bgColor
                    ? 'register-calendar-day-item lastday'
                    : 'register-calendar-day-item'
                "
              >
                <span v-if="!item.isRmb">{{ item.day }}</span>
                <img
                  v-else
                  class="rmb"
                  src="@/assets/image/gold-coin.png"
                  alt="图片"
                />
              </div>
            </div>
          </div>
        </div>
        <div @click="checkin" :class="['btn2', { 'btn-opacity': isComplete }]">
          {{ isComplete ? "已签到" : "今日签到" }}
        </div>
        <div class="tips">
          注：每天只能签到一次，签到成功后，系统将会自动奖励您1金币，签到过程中，如果遇到任何问题，请联系我们的官方公众号客服。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDateList } from "@/utlis/calendar.js";
export default {
  data() {
    return {
      weekList: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      answerRegisterStatus: {
        answerDialogVisible: false,
        dateList: [],
        answerIndex: "",
        registerDialogVisible: false,
        question: {}
      },
      isComplete: false,
      takeInLoading: false,
      userid: null
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getTodayComplate() {
      await this.$http
        .get(`/api/v1/Accounts/${this.userid}/TodayStatus`)
        .then(v => {
          this.isComplete = v.data.isComplete;
          this.answerRegisterStatus.dateList = getDateList(v.data.days);
        });
    },

    //点击签到
    async checkin() {
      //签到修改
      if (this.isComplete || this.takeInLoading) {
        //用户签到过后直接显示日历签到
        return;
      }
      this.takeInLoading = true;
      // 判断是否存在问题,空对象直接显示日历
      const {
        data: { isComplete }
      } = await this.$http.post(`/api/v1/Accounts/${this.userid}/Today`);
      if (isComplete) {
        this.$toast("签到成功");
        this.isComplete = true;
        await this.getTodayComplate();
      }
      this.takeInLoading = false;
    }
  },
  async created() {
    if (localStorage.getItem("userid")) {
      this.userid = localStorage.getItem("userid");
    } else {
      this.$router.push("/mobile");
    }
    this.getTodayComplate();
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    z-index: 99;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }
  .mian-content {
    width: 100%;
    .bg {
      width: 100%;
      height: 226px;
      background-image: url("../../assets/image/bg1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .gold-box {
        position: relative;
        width: 200px;
        left: 50%;
        transform: translateX(-50%);
        top: 45px;
        .gold-coin2 {
          position: absolute;
          width: 113px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          top: 12px;
        }
        .gold-coin2-bg {
          position: absolute;
          width: 165px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 0;
        }
      }
    }
    .content {
      padding: 0 15px;
      position: relative;
      z-index: 2;
      top: -70px;
      .sign-in {
        width: 100%;
        border-radius: 30px 30px 0 0;
        background-color: #fff;
        padding-bottom: 20px;
        .sign-top {
          padding-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ff643c;
          font-size: 14px;
          font-weight: bold;
          img {
            width: 36px;
            height: 1px;
          }
          .img-right {
            margin-left: 30px;
          }
          .img-left {
            margin-right: 30px;
            transform: rotate(180deg);
          }
        }
        .register-calendar-main {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        .register-calendar-week {
          width: 14.285%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          font-size: 12px;
          margin-top: 10px;
          color: #0a0a0a;
        }
        .register-calendar-day {
          width: 14.285%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 12px;
          color: #0a0a0a;
        }
        .register-calendar-day-item {
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 5px;
          /* cursor: pointer; */
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .lastday {
          color: #dac5c5;
          cursor: auto;
        }
        .rmb {
          width: 22px;
          height: 22px;
        }
      }
      .btn2 {
        width: calc(100% - 80px);
        height: 46px;
        background: linear-gradient(138deg, #ffa200 0%, #ff6017 100%);
        border-radius: 25px 25px 25px 25px;
        text-align: center;
        line-height: 46px;
        color: #ffffff;
        font-size: 18px;
        box-sizing: border-box;
        margin-left: 40px;
        margin-top: 30px;
      }
      .tips {
        color: #ff6017;
        font-size: 14px;
        margin-top: 38px;
      }
      .btn-opacity {
        opacity: 0.6;
      }
    }
  }
}
</style>
